import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import 'muse-ui/lib/styles/base.less';
import { Button, TextField } from 'muse-ui';
import 'muse-ui/lib/styles/theme.less';
import { Notify ,Dialog, PullRefresh ,Toast,NavBar,Empty,Cell, CellGroup ,Form ,Field ,Calendar  } from 'vant';

Vue.use(Button);
Vue.use(TextField);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(PullRefresh);
Vue.use(Toast);
Vue.use(NavBar);
Vue.use(Empty);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Calendar);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
