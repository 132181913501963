

/*
*
* 路由器对象模块
*
* */

import Vue from "vue"
import VueRouter from 'vue-router'
import homepage from "@/pages/homepage";
import selfCenter from "@/pages/selfCenter";
import loginPage from "@/pages/loginPage";
import ProStatusPage from "@/pages/ProStatusPage";
import deptProjectListPage from "@/pages/deptProjectListPage";
import infoDataPage from "@/pages/infoDataPage";
import techniciansStatusPage from "@/pages/techniciansStatusPage/techniciansStatusPage";

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    base: '/wxmq/',
    routes:[

        {
            path: '/',
            component: loginPage
        },
        {
            path: '/homepage',
            component: homepage
        },
        {
            path: '/selfCenter',
            component: selfCenter
        },
        {
            path: '/ProStatusPage',
            component: ProStatusPage
        },
        {
            path: '/deptProjectListPage',
            name: 'deptProjectListPage',
            component: deptProjectListPage
        },
        {
            path: '/infoDataPage',
            name: 'infoDataPage',
            component: infoDataPage
        },
        {
            path: '/techniciansStatusPage',
            name: 'techniciansStatusPage',
            component: techniciansStatusPage

        }


    ]

})