<template>
  <div class="app-body">
    <van-nav-bar
        title="上钟详情列表"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-pull-refresh class="refresh-List-View" v-model="isLoading" @refresh="onRefresh" >

      <div class="body">
        <van-empty description="没有需要服务的床位" v-if="showWitchView"/>
        <div class="workList" v-else >
          <workStatueItem v-for="(item , index) in myDataArray" :key="item.id + item.status" :index="index" :item-info="item"
          v-on:myEvent="loadData" v-on:myEventAddSub="gotoSelectProPage" v-on:myEventChangeSub="gotoSelectProPageWithChangeSub"/>

        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import {getWorkinfoList} from '@/api/staff_api'
import {Toast} from 'vant'
import workStatueItem from "@/components/workStatueItem";
import globalVar from "@/utils/globalVar";

export default {



  name: "ProStatusPage",
  data() {
    return {
      count: 0,
      isLoading: false,
      myDataArray: ''
    };
  },
  components:{
    workStatueItem : workStatueItem
  },
  computed:{
    showWitchView: function (){
      return this.myDataArray.length == 0
    }
  },
  methods: {

    onClickLeft(){
      this.$router.go(-1)
    },

    gotoSelectProPage(roomId){
      this.$router.push({
        name: 'deptProjectListPage',
        params: {
          roomId: roomId,
          change: false
        }
      })
    },

    gotoSelectProPageWithChangeSub(subId){
      this.$router.push({
        name: 'deptProjectListPage',
        params: {
          subjectId: subId,
          change : true

        }
      })
    },

    onRefresh() {
      getWorkinfoList({
        userId: globalVar.userId
      }).then((response)=>{
        Toast('刷新成功');
        this.isLoading = false;
        
        if (response.code){
          console.log(response)
          this.myDataArray = response.data
        }
      })
    },

    loadData(){
      this.isLoading = true;
      this.onRefresh()
    }
  },


  mounted() {
    this.loadData()

  }

}
</script>

<style lang="scss" scoped>

  .refresh-List-View{
    min-height: calc(100vh - 40px);
  }

</style>