const global = {

    userId : '',
    openId : '',
    deptId : '',
    isFirstEnter : true

};

export default global


// userId : '010928cd72dc4547b3f9f994d6e71037',
//     openId : '',
//     deptId : ''