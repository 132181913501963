<template>

  <div class="selfView">
    <div class="top-View">
      <p class="left-Name-top">床位:</p>
      <p class="title-Max-top">{{itemInfo.berthName}}</p>
      <mu-button class="addBtn" small color="info" @click="_addsubProject">加钟</mu-button>
    </div>

    <div class="body">


      <div class="titleCell">

        <div class="division-line"/>

        <div class="subjectItemView"  v-for="item in itemInfo.subjectFlowList" :key = "item.id">
          <div class="SubContent-View">
            <div class="CellText">
              <p class="left-Name-XM">项目:</p>
              <p class="title-Max-XM">{{item.subjectName}}</p>
              <mu-button class="changeBtn" small color="info" @click="_changeSubProject(item.id)">换项目</mu-button>
            </div>

            <div class="subSt" v-if="item.startTime">
              <p class="textP">{{getTimeWithtype(item.startTime,item.estimateEndTime,item.endTime,'1',item.status)}}</p>
              <p class="textP" v-if="item.estimateEndTime">{{getTimeWithtype(item.startTime,item.estimateEndTime,item.endTime,'2')}}</p>
              <p class="textP" v-if="item.endTime">{{getTimeWithtype(item.startTime,item.estimateEndTime,item.endTime,'3')}}</p>
            </div>

          </div>
          <div class="division-line"/>

        </div>



        <div class="Content-View">
          <p class="left-Name">房间状态:</p>

          <p class="title-Max red-p">{{RoomStatus.subStatus}}</p>
        </div>
        <div class="division-line"/>
        <div class="Content-View">
          <p class="left-Name">预计整体结束时间:</p>

          <p class="title-Max red-p">{{surplusTime}}</p>
        </div>
        <div class="bottom-View">
          <mu-button fab small @click="bindInto" :color="RoomStatus.color">{{RoomStatus.title}}</mu-button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {onWork , upWork} from '@/api/staff_api'
import {Dialog} from "vant";
import globalVar from '@/utils/globalVar'
const moment = require('moment');

export default {
  name: "workStatueItem",
  data(){
    return{
      RoomStatus : '',
      SubjectName : ''
    }
  },
  props:['itemInfo'],
  computed : {

      surplusTime : function (){
        if (!this.itemInfo.estimateEndDate){
          return "无预计时间"
        }
        return moment(this.itemInfo.estimateEndDate).format('HH:mm')
      }

  },

  methods:{
    //添加项目
    _addsubProject(){
      this.$emit('myEventAddSub',this.itemInfo.id)
    },


    bindInto(){
      let type = this.itemInfo.status
      if (type == '1'){
        onWork({
          roomId : this.itemInfo.id,
          userId : globalVar.userId
        }).then(response=>{
          Dialog.alert({
            message: response.msg,
          }).then(() => {
            this.$emit('myEvent')
          })
        })
      }else if(type == '2'){
        upWork({
          roomId : this.itemInfo.id,
          userId : globalVar.userId
        }).then(response=>{
          Dialog.alert({
            message: response.msg,
          }).then(() => {
            this.$emit('myEvent')
          })
        })
      }else if(type == '3'){
        Dialog.alert({
          message: '该状态无法点击',
        }).then(() => {
          // on close
        })
      }
    },

    _changeSubProject(changeId){
      this.$emit('myEventChangeSub',changeId)
    },

    // getSubjectName(obj){
    //   let retName;
    //   let dataArray = obj.subjectFlowList;
    //   [...dataArray].forEach(sub => {
    //     retName =  typeof retName == 'undefined'? '': retName;
    //     retName = retName + " " + sub.subjectName
    //   })
    //   return retName
    // },

    getTimeWithtype(starTime,estimateEndTime,endTime,type,status){

      if(type === '1'){
        let time = moment(starTime).format('hh:mm');
        let statuStr = '';
        //根据状态获取时间
        if(status === '0'){
          statuStr = '未安排';
        }else if(status === '1'){
          statuStr = '等待服务中';
        }else if(status === '2'){
          //计算剩余时间
          if(estimateEndTime){
            
            let t1 = moment()
            let t2 = moment(estimateEndTime)
            let t3 = t2.diff(t1, 'minute')
            let str3 = t3 >= 0? '剩余' : '超时';
            statuStr = str3 + t2.diff(t1, 'minute') + '分钟'

          }else {
            statuStr = '服务中';
          }

        }else if(status === '3'){
          statuStr = '服务完毕';
        }else if(status === '4'){
          statuStr = '退钟';
        }


        return '开始时间：' + time + '(' + statuStr  + ')';
      }else if(type === '2'){
        let time = moment(estimateEndTime).format('hh:mm');
        return '预计下钟时间：' + time;
      }else if(type === '3'){
        let time = moment(endTime).format('hh:mm');
        return '下钟时间：' + time;
      }

    },

    getRoomStatus:function (){
      switch (this.itemInfo.status){
        case '1' : return {color:'Primary',title:'上钟',subStatus:'等待中'}; //存在未完成的项目可上钟

        case '2' : return {color:'warning',title:'下钟',subStatus:'上钟状态'}; //该房间处于上钟状态

        case '3' :  return {color:'error',title:'已完成',subStatus:'已完成'}; //该房间所有项目已完成

        default :
          return {color:'Primary',title:'上钟',subStatus:'未完成'}
      }
    }
  },

  mounted() {
    // this.SubjectName = this.computedSubjectName()
    this.RoomStatus = this.getRoomStatus()

  }
}
</script>

<style lang="scss" scoped>


    .left-Name-top{
      font-size: 18px;
      font-weight: 600;
      margin: 3px 3px 5px 20px;
    }

    .title-Max-top{
      font-size: 17px;
      font-weight: 600;
      margin: 3px 3px 5px 3px;
    }

    .top-View{
      margin: 10px 10px 0px 10px;
      display: flex;
      align-items: center;
      justify-content: start;
      overflow: hidden;

      .addBtn{
        position: absolute;
        //left: calc(33vw);
        right: 35px;

      }
    }

  .body{

    border-radius: 7px;
    margin: 3px 20px 20px 20px;
    border:2px solid #b8b6b6;


    .titleCell{


      .Content-View{
        display: flex;
        align-items: center;
        justify-content: start;
        flex-wrap: wrap;


      }

      .SubContent-View{

        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;


        .CellText{
          display: flex;
          align-items: center;
          justify-content: start;
          flex-wrap: wrap;
        }

        .subSt{
          display: flex;
          align-items: start;
          justify-content: start;
          flex-direction: column;
          .textP{
            margin: 3px 0px 10px 10px;
            font-size: 14px;
          }
        }
      }

      .bottom-View{
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .left-Name-XM{
      font-size: 16px;
      margin-left: 10px;
      font-weight: 600;
    }

    .title-Max-XM{
      font-size: 16px;
      margin-left: 10px;
      font-weight: 600;
    }

    .left-Name{
      font-size: 16px;
      margin-left: 10px;
    }

    .title-Max{
      font-size: 16px;
      margin-left: 5px;
    }

    .red-p{
      color: #f12121;
    }


    .division-line{
      width: 100%;
      height: 1px;
      background-color: #b8b6b6;
    }

    .changeBtn{
      position: absolute;
      right: 35px;
    }
  }


</style>