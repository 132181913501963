import request from '@/utils/request'

// 绑定手机号码
export function bindPhone(query) {
    return request({
        url: '/wx/AuthUser/bindingUser',
        method: 'get',
        params: query
    })
}


// 发送验证码
export function sendCode(phone) {
    return request({
        url: '/wx/AuthUser/getVerificationCode',
        method: 'get',
        params: phone
    })
}





