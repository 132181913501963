<template>
  <div class="app-body">
    <van-nav-bar
        title="项目详情列表"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-pull-refresh class="refresh-List-View" v-model="isLoading" @refresh="loadData" >

      <div class="body">
        <van-empty description="数据获取失败" v-if="showWitchView"/>
        <div class="proItemCell" v-else >
          <van-cell  v-for="item in subProArray" :key="item.id" :title="item.subjectName" is-link  @click="itemUpInto(item.id)"
                     :value="'价格：'+item.subjectPrice" :label="'时长：'+ item.timeLength"/>
        </div>
      </div>
    </van-pull-refresh>
  </div>

</template>

<script>

import {getProList,staffAddProWork,staffChangeProWork} from '@/api/staff_api'
import globalVar from '@/utils/globalVar'
import {Dialog} from "vant";

export default {
  name: "deptProjectCell",
  data(){
    return {
      subProArray : [],
      isLoading : false,
      showWitchView : false
    }
  },
  props:[],
  methods: {
    loadData(){
      this.isLoading = true;
      //获取店铺项目列表
      getProList({
        deptId : globalVar.deptId
      }).then((response)=>{

        if (response.code){
          this.subProArray = response.data
        }

      }).finally(()=>{
        this.isLoading = false;
        this.showWitchView = this.subProArray.length === 0? true : false;
      })
    },
    itemUpInto(subItem){

      const  roomId = this.$route.params.roomId;
      const  subjectId = this.$route.params.subjectId;
      const  isChange = this.$route.params.change;

      if (isChange){

        staffChangeProWork({
          subjectFlowId : subjectId,
          subjectId : subItem,
          userId : globalVar.userId,
          roomId : roomId
        }).then(res=>{
          if (res.code == '200'){
            Dialog.alert({
              message: res.msg,

            }).then(() => {
              this.onClickLeft()
            });
          }
        })

      }else {
        staffAddProWork({
          subjectId : subItem,
          userId : globalVar.userId,
          roomId : roomId
        }).then(res=>{
          if (res.code == '200'){
            Dialog.alert({
              message: res.msg,

            }).then(() => {
              this.onClickLeft()
            });
          }
        })
      }



    },

    onClickLeft(){
      this.$router.go(-1)
    }

  },


  mounted() {
    this.loadData()

  }

}
</script>

<style scoped>
.refresh-List-View{
  min-height: calc(100vh - 40px);
}
</style>