<template>
  <div class="componentsView">
    <div class="top-View">
        <p class="p-max">{{itemData.employeeNumber + '号'}}<span class="p-span">{{itemData.userName}}</span></p>
    </div>
    <div class="Content-View" :class="cssStatus">
      <p class="statusValue">{{status}}</p>
      <p class="p-time">{{waitTime}}</p>
    </div>
  </div>
</template>

<script>

  const moment = require('moment');

  export default {
    name: "techniciansItem",

    props:['itemData'],

    data(){
      return {
        waitTime : '-',
        status : '未开单',
        cssStatus : ''
      }
    },

    computed :{
      // workStatus(){
      //
      //
      //   return this.changeStatus(this.itemData.status)
      //
      // },
      //
      // waitTime(){
      //   return '分钟'
      // }

    },

    methods : {
      changeStatus(st){

        if(st.status == '2'){
          this.status = '休息中'
          this.cssStatus = 'backColorDef-Content'
        }else if (st.status == '3'){
          this.status = '休假中'
          this.cssStatus = 'backColorKeep-Content'
        }else if (st.predetermineStatus == '1'){
          this.status = '预约中'
          this.cssStatus = 'backColorKeep-Content'
        }else {
          //计算时间

          if(st.list.length > 0){
            let allTime = 0;
            let mixStartTime = undefined;
            st.list.forEach(item => {
              allTime += item.serviceTimeLength;
              if(item.startTime){
                if(mixStartTime){
                  mixStartTime = moment(mixStartTime).diff(moment(item.startTime)) < 0 ? mixStartTime :item.startTime;
                }else {
                  mixStartTime = item.startTime;
                }
              }
            })

            if(mixStartTime){

              let val = moment().unix() - (moment(mixStartTime).unix() + allTime * 60);
              let min = (val/60).toFixed()

              this.status = min > 0 ? '超时' : '待下钟';
              this.cssStatus = min > 0 ? 'backColorWait-Content' : 'backColorWork-Content';
              this.waitTime = -min + '分钟';


            }else {
              //未上钟
              this.status = '未上钟';
              this.cssStatus = 'backColorWait-Content'
            }
          }else {
            this.status = '未开单';
            this.cssStatus = ''
          }
        }

      }
    },


    mounted() {
      this.changeStatus(this.itemData);
    }
  }
</script>

<style lang="scss" scoped>


  p{
    margin: 5px;
  }

.componentsView{
  width: 25vw;
  height: 32vw;
  margin: 5px 10px;
  border: 1px solid rgb(230, 235, 245);
  background-color: white;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .top-View{
    flex: 1;
    color: black;
    .p-max{
      font-size: 16px;
      font-weight: 550;
    }
    .p-span{
      font-size: 12px;
    }

  }

  .Content-View{
    width: 100%;
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .statusValue{
      font-size: 14px;

    }
    .p-time{
      font-size: 12px;
    }
  }

}



.backColorDef-top{
  background-color: #bab8b8;
}




//待上钟  待下钟  未开单

  //休息中 未开单
  .backColorDef-Content{
    background-color: rgb(189, 183, 188);
    color: black;
  }

  //待下钟
  .backColorWork-Content{
    background-color: #2a8aec;
    color: white;
  }

  //待上钟
  .backColorWait-Content{
    background-color: #2a8aec;
    color: #e30202;
  }

  //预约中
  .backColorKeep-Content{
    background-color: #c24252;
    color: white;
  }





</style>