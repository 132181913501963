import request from '@/utils/request'

// 绑定手机号码
export function bindPhone(query) {
    return request({
        url: '/monitor/job/list',
        method: 'get',
        params: query
    })
}

//上班
export function startWork(query) {
    return request({
        url: '/wx/staff/startWork',
        method: 'get',
        params: query
    })
}

//下班
export function endWork(query) {
    return request({
        url: '/wx/staff/endWork',
        method: 'get',
        params: query
    })
}

//获取技师 上钟情况
export function getWorkinfoList(query) {
    return request({
        url: '/wx/subjectflow/selectAllSubjectByUserId',
        method: 'get',
        params: query
    })
}


//上钟
export function onWork(query) {
    return request({
        url: '/wx/subjectflow/atWorkUpdate',
        method: 'get',
        params: query
    })
}

//下种
export function upWork(query) {
    return request({
        url: '/wx/subjectflow/offWorkUpdate',
        method: 'get',
        params: query
    })
}

//获取项目列表
export function getProList(query) {
    return request({
        url: '/wx/subjectflow/selectAllSubject',
        method: 'get',
        params: query
    })
}


//获取个人信息
export function getSelfUserInfo(query) {
    return request({
        url: '/wx/AuthUser/selectSysUserByUserId',
        method: 'get',
        params: query
    })
}

//加钟
export function staffAddProWork(query) {
    return request({
        url: '/wx/subjectflow/addClockSubjectFlow',
        method: 'post',
        params: query
    })
}

//修改项目
export function staffChangeProWork(query) {
    return request({
        url: '/wx/subjectflow/exchangeSubject',
        method: 'get',
        params: query
    })
}


//获取项目记录
export function staffgetProWorkInfo(query) {
    return request({
        url: '/wx/subjectflow/selectSubjectFlowByUserId',
        method: 'get',
        params: query
    })
}


//获取排钟信息
export function staffgetAllProWorkInfo(query) {
    return request({
        url: '/wx/subjectflow/selectUserList',
        method: 'get',
        params: query
    })
}






