<template>
  <div class="appView">
    <div class="app-login" v-if="isShowBindPhoneView">
      <div style="display: flex;flex-wrap: wrap;align-items: center;">
        <div>
          <mu-text-field class="leftTextInput" style="width: calc(100vw - 140px)"   v-model="phoneNumb" placeholder="请输入手机号码"></mu-text-field>
          <mu-button flat color="primary" style="max-width: 120px" @click="getVCode">{{VCodeBtnTitle}}</mu-button><br>
        </div>
        <mu-text-field class="leftTextInput" v-model="VCodeNumb" style="width: calc(100vw - 140px)" placeholder="请输入验证码"></mu-text-field>
      </div>
      <mu-button color="primary" style="margin-top: 50px" @click="bindPhoneBtnUpInto">确定</mu-button>

    </div>
    <div class="app-body" v-else>


      <van-pull-refresh class="refresh-List-View" v-model="isLoading" @refresh="loadUserInfo" >

        <div class="body">

          <van-cell-group inset>
            <van-cell title="姓名" :value="userInfo.nickName" size="large" is-link></van-cell>
            <van-cell title="状态" :value="employeeStatus" size="large" is-link></van-cell>
          </van-cell-group>

          <div class="functionView">

            <mu-button class="itemBtn" slot="actions" color="primary" @click="getWorkinfoListBtn">我的开单</mu-button>
            <mu-button class="itemBtn" slot="actions" color="primary" @click="getSelfInfoData">做工记录</mu-button>
            <mu-button class="itemBtn" slot="actions" color="primary" @click="getTeachStatus">当前排钟</mu-button>
            <mu-button class="itemBtn" slot="actions" color="primary" @click="startWorkBtn">上班</mu-button>
            <mu-button class="itemBtn" slot="actions" color="warning" @click="endWorkBtn">下班</mu-button>
          </div>

        </div>
      </van-pull-refresh>

    </div>

  </div>


</template>

<script>
// import anime from "animejs";
import {sendCode,bindPhone} from "@/api/base_api";
import {startWork , endWork ,getSelfUserInfo} from "@/api/staff_api"
import globalVar from '@/utils/globalVar'
import {Dialog} from 'vant'
import {setToken} from '@/utils/auth'

export default {
  name: "loginPage",
  data(){
    return{
      phoneNumb : '',
      VCodeBtnTitle : '获取验证码',
      VCodeBtnNumb : '',
      VCodeNumb : '',
      VCodeIsSend : false,
      openSimple: false,
      errorMsg:'',
      timeObj : '',
      isShowBindPhoneView:false,
      userInfo:{},
      isLoading:false,
      isFirstEnter: true
    }
  },

  computed:{
    employeeStatus : function () {
      return  this.userInfo.employeeStatus == '1' ? '已上班' : '休息中'
    }
  },
  methods: {

    changetype(){
      this.isShowBindPhoneView = !this.isShowBindPhoneView;
    },

    loadUserInfo(){
      // globalVar.userId = 'd8f391507edd414391a005918a8762d7'

      this.isLoading = true
      getSelfUserInfo({
        userId : globalVar.userId
      }).then(response =>{

        if (response.code == '200'){
          this.userInfo = response.data
          globalVar.deptId = this.userInfo.deptId

        }else {
          Dialog.alert({
            message: '个人信息获取失败',
          }).then(() => {
            // on close
          });
          this.isLoading = false;
        }

      }).catch(()=>{
        Dialog.alert({
          message: '个人信息获取失败',
        }).then(() => {
          // on close
        });
        this.isLoading = false;
      }).finally(()=>{
        this.isLoading = false;
      })
    },

    startWorkBtn(){
      startWork({
        userId : globalVar.userId
      }).then(response =>{

        Dialog.alert({
          message: response.msg,
        }).then(() => {
          // on close
          this.userInfo.employeeStatus = "1"
        });
      })
    },

    endWorkBtn(){

      Dialog.confirm({
        message: "您确定要下班吗？",
      }).then(() => {
        endWork({
          userId : globalVar.userId
        }).then(response =>{
          Dialog.alert({
            message: response.msg,
          }).then(() => {
            // on close
            this.userInfo.employeeStatus = "2"
          })
        })
      }).catch(() => {

      });


    },

    getWorkinfoListBtn(){
      globalVar.isFirstEnter = false;
      //跳转页面
      this.$router.push({path:'/ProStatusPage'})

    },

    getSelfInfoData(){
      //跳转页面
      this.$router.push({path:'/infoDataPage'})
    },

    getTeachStatus(){
      //跳转页面
      this.$router.push({path:'/techniciansStatusPage'})
    },


    //检查手机号码
    cheakPhoneNumb(){
      if (this.phoneNumb.length <= 0){
        this.errorMsg = '请输入电话号码'
        Dialog.alert({
          message: this.errorMsg,
        }).then(() => {
          // on close
        })
        return false
      }

      if (!(/^1[3456789]\d{9}$/.test(this.phoneNumb))) {
        this.errorMsg = '请输入正确的电话号码'
        Dialog.alert({
          message: this.errorMsg,
        }).then(() => {
          // on close
        })
        return false
      }
      return true
    },


    destroyMytime() {
      clearInterval(this.timer);
    },

    reduceTime(){
      if (this.VCodeBtnNumb <= 0 ){
        this.VCodeBtnTitle = '获取验证码'
        this.VCodeIsSend = false
        this.destroyMytime()
      }else {
        this.VCodeBtnNumb -= 1
        this.VCodeBtnTitle = '' + this.VCodeBtnNumb
      }
    },

    getVCode(){
      if (this.VCodeIsSend)
        return

      if(this.cheakPhoneNumb()){

        sendCode({
          phoneNumber : this.phoneNumb
        }).then(response => {
          if (!response)
            return
          //开启倒计时线程
          this.VCodeIsSend = true
          this.VCodeBtnNumb = 60
          this.VCodeBtnTitle = ''+this.VCodeBtnNumb
          this.timer = setInterval(this.reduceTime, 1000);
        })
      }
    },

    bindPhoneBtnUpInto(){

      if (this.cheakPhoneNumb()){
        if (this.VCodeNumb.length == 0)
          return

        bindPhone({
          phoneNumber : this.phoneNumb,
          openId : globalVar.openId,
          verificationCode : this.VCodeNumb
        }).then(response => {
          // console.log(response)

          // this.$router.replace({}
          if (response.code == 200){
            globalVar.userId = response.data.userId
          console.log( response.data)
         
          //存入cookie
          setToken(response.data.token)
            Dialog.alert({
              message: '成功',
            }).then(() => {
              this.isShowBindPhoneView = false
              this.loadUserInfo()
            });
          }
        })
      }
    },

    // closeSimpleDialog(){
    //   this.openSimple = false
    // },

    getCode() { // 非静默授权，第一次有弹框
      this.openId = this.getUrlCode().openId // 截取penId
      this.userId = this.getUrlCode().userId
      globalVar.openId = this.openId
      globalVar.userId = this.userId
      if (this.userId == null || this.userId === '') { // userId，则去请求

        //显示登录页面 绑定手机
        this.isShowBindPhoneView = true

        // this.isShowBindPhoneView = false

      } else {
        this.isShowBindPhoneView = false
        // 你自己的业务逻辑
        this.loadUserInfo()
      }
    },


    getUrlCode() { // 截取url中的code方法

      let local = window.location.href // 获取页面url


      let theRequest = new Object()
      if (local.indexOf("?") != -1) {
        let strsArray = local.split("?")
        let str = [...strsArray][1]
        let strs = str.split("&")
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    }

  },

  mounted() {
    // debugger
    // console.log(this.userInfo)

    this.getCode()
    if (!this.isShowBindPhoneView){
      this.loadUserInfo()
    }



    if(globalVar.isFirstEnter && !this.isShowBindPhoneView){

      this.getWorkinfoListBtn()

    }

    // this.testRun()
  }
}
</script>

<style lang="scss" scoped>

  .app-login{
    margin-top: 30px;
  }

  .leftTextInput{
    padding-left: 10px;
  }

  .functionView{
    margin: 20px;
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .itemBtn{
      margin: 30px;
    }
  }


</style>