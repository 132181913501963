<template>
  <div class="viewBody">
    <van-nav-bar
        title="技术上钟状态"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-pull-refresh class="refresh-List-View" v-model="isLoading" @refresh="loadData" >

      <div class="loadingBody">
        <techniciansItem class="technicain-Item" v-for="item in mDataArray" :key="item.userId" :item-data="item"></techniciansItem>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
  import techniciansItem from "@/components/techniciansItem";

  import {staffgetAllProWorkInfo} from "@/api/staff_api"

  import globalVar from "@/utils/globalVar";
  import {Dialog} from "vant";


  export default {
    name: "techniciansStatusPage",
    components : {
      techniciansItem : techniciansItem
    },
    data(){
      return {
        isLoading : false,
        mDataArray :[]
      }
    },

    methods : {
      onClickLeft(){
        this.$router.go(-1)
      },

      loadData(){
        this.isLoading = true;
        staffgetAllProWorkInfo({
            deptId : globalVar.deptId
          }).then(response =>{
            if(response.code == '200'){
             
              this.mDataArray = response.data;
            }else {
              Dialog.alert({
                message: response.msg,
              })
            }
          }).finally(()=>{
            this.isLoading = false;
          });
      }

    },


    mounted() {
      this.loadData()
    }


  }
</script>

<style lang="scss" scoped>

  .loadingBody{
    width: 100%;
    height: 100%;
  }

  .technicain-Item{
    float: left;
  }

  .refresh-List-View{
    width: 100vw;
    height: calc(100vh - 40px);
  }

</style>