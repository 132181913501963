<template>
  <div>
    <van-nav-bar
        title="项目详情列表"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <div>
      <van-cell title="选择日期区间" :value="dateStr" @click="show = true"  is-link/>
      <van-cell title="总计开单:" :value="totalNumb"/>
      <van-cell-group title="服务记录">
        <van-cell :title="item.subjectName" :value="getValueTime(item.berthName,item.endTime)" :label="'提成：' + item.commissionAmount" v-for="item in dataArray" :key="item.id"/>
      </van-cell-group>
      <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" :default-date="defaultDate"/>
    </div>
  </div>

</template>

<script>
import {staffgetProWorkInfo} from "@/api/staff_api";
import globalVar from "@/utils/globalVar";
const moment = require('moment');

export default {
  name: "infoDataPage",
  data() {
    return {
      date: {
        startDate : '',
        endDate : ''
      },
      show: false,
      dataArray : [],
      dateStr : '',
      totalNumb : 0,
      minDate: new Date(moment().subtract(3,'months').year(),moment().subtract(3,'months').month(),moment().add(1,'days').date()),
      maxDate: new Date((new Date()).getTime() + 24*60*60*1000),

    };
  },

  computed : {


    defaultDate(){

      return [this.date.startDate,this.date.endDate]
    }

    // dateStr() {
    //   if (this.date.startDate && this.date.endDate){
    //     return  `${this.date.startDate.getMonth() + 1}/${this.date.endDate.getDate()}`;
    //   }else {
    //     return  `${this.date.startDate.getMonth() + 1}/${this.date.endDate.getDate()}`;
    //   }
    //
    // },

  },

  methods: {

    getValueTime(berthName,time){
      let reTiem = '';
      if (time){
        reTiem = moment(time).format('YYYY-MM-DD HH:mm ')
        return berthName + '(' + reTiem  + ')'
      }else {
        return berthName + '(未结束)'
      }

    },

    onClickLeft(){
      this.$router.go(-1)
    },

    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      // this.dateStr = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.date.startDate = start;
      this.date.endDate = end;
      this.loadData();
    },

    loadData(){

      let nowDate = new Date();
      let startDate = this.date.startDate || (this.date.startDate = nowDate)
      let endDate = this.date.endDate || (this.date.endDate = new Date(nowDate.getTime() + 24*60*60*1000))
      this.dateStr = `${this.date.startDate.getMonth()+1}/${this.date.startDate.getDate()}-${this.date.endDate.getMonth()+1}/${this.date.endDate.getDate()}`

      let startDateStr = moment(startDate).format("YYYY-MM-DD");
      let endDateStr = moment(endDate).format("YYYY-MM-DD");
      staffgetProWorkInfo({
        userId: globalVar.userId,
        startDate : startDateStr,
        endDate : endDateStr,
        pageSize : 50,
        pageNo : 1
      }).then((response)=>{

        if (response.code == 200){
          this.dataArray = response.rows
          this.totalNumb = response.total
        }

      })
    }
  },
  mounted() {
    this.loadData();

  }
}
</script>

<style scoped>

</style>